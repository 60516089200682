import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import Topbar from '../components/Topbar';
import Footer from '../containers/Footer';
import Hero from '../containers/Hero';
import PoapContainer from '../containers/PoapContainer';
import SmoothScroll from '../containers/SmoothScroll';
import fadeTransition from '../data/fadeTransition';
import useWindowProps from '../hooks/useWindowProps';

type HeroPlaceholderProps = {
  height: number;
};

const HeroPlaceholder = styled.div<HeroPlaceholderProps>`
  height: ${(props) => props.height}px;
  pointer-events: none;
`;

const Home = (): JSX.Element => {
  const size = useWindowProps();

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit={{ opacity: 0 }}
      transition={fadeTransition}
    >
      <Hero height={size.height} />
      <SmoothScroll>
        <Topbar />
        <HeroPlaceholder height={size.height} />
        <PoapContainer />
        <Footer />
      </SmoothScroll>
    </motion.div>
  );
};

export default Home;
