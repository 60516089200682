import React from 'react';
import styled from 'styled-components';
import AddressClaim from './AddressClaim';
import ArtistModal from './ArtistModal';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    margin-bottom: 7rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    margin-bottom: 15rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

type ActionBarProps = {
  poapID: string;
};

const ActionBar = ({ poapID }: ActionBarProps): JSX.Element => (
  <Wrapper>
    <AddressClaim poapID={poapID} />
    <ArtistModal />
  </Wrapper>
);

export default ActionBar;
