import create from 'zustand';

type ModalState = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const useStore = create<ModalState>((set) => ({
  open: false,
  setOpen: (open: boolean) => set(() => ({ open })),
}));

export default useStore;
