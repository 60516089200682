import React from 'react';

export const spaceText = (text: string): string[] => {
  if (!text.includes('\n')) {
    return [text];
  }

  return text.split('\n');
};

export const formatText = (text: string, ignore?: boolean): string | JSX.Element => {
  if (!text.includes('**')) {
    return text;
  }

  if (ignore) return text.replaceAll('**', '');

  const splitted = text.split('**');

  return (
    <span>
      {splitted.map((item, index) => (index % 2 === 0 ? item : <strong key={item}>{item}</strong>))}
    </span>
  );
};

export const sleep = (ms: number): Promise<void> => (
  new Promise((resolve) => setTimeout(resolve, ms))
);
