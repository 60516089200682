import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import Button, { ButtonAppearance, Theme } from '../components/Button';
import { Poap } from '../data/poaps';
import { formatText, spaceText } from '../utils/utils';
import Close from '../assets/icons/close.svg';
import ActionBar from '../components/ActionBar';
import useWindowProps from '../hooks/useWindowProps';
import Link from '../components/Link';

const Wrapper = styled.div`
  width: calc(100rem - 27.64rem);
  padding: 0 13.82rem;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  pointer-events: all;
  margin: 0 auto;

  @media (orientation: portrait) and (max-width: 767px) {
    width: calc(100% - 15.64rem);
    padding: 0 7.82rem;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10.93rem;
  margin-bottom: 3rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    margin-top: 14rem;
    margin-bottom: 5rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    margin-top: 27rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 3rem;

  @media (orientation: portrait) and (max-width: 767px) {
    width: 100%;
    margin-top: 5rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 0;
  }
`;

const Titles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Auxiliary = styled.h5`
  color: ${({ theme }) => theme.colors.auxiliary};
  font-weight: 400;
  font-size: 1.09rem;
  line-height: 1.56rem;
  margin: 0;
  margin-bottom: 2.81rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.22rem;
    line-height: 1.83rem;
    margin-bottom: 4rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3.17rem;
    line-height: 9.87rem;
    margin-bottom: 4.44rem;
  }
`;

const AuxiliaryButton = styled.button`
  font-family: 'Poppins', 'sans-serif';
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.auxiliary};
  font-weight: 400;
  font-size: 1.09rem;
  line-height: 1.56rem;
  margin: 0;
  cursor: pointer;
  padding: 0;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.22rem;
    line-height: 1.83rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3.17rem;
    line-height: 9.87rem;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.title};
  font-weight: 500;
  font-size: 3.75rem;
  line-height: 5.62rem;
  margin: 0;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 4.2rem;
    line-height: 6rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 5.55rem;
    line-height: 8.33rem;
    margin-bottom: 5.55rem;
    margin-right: 10rem;
  }
`;

const Image = styled.img`
  width: 35rem;
  height: 35rem;
  border-radius: 100%;
  object-fit: cover;
  margin-bottom: 3rem;

  @media (orientation: portrait) and (max-width: 767px) {
    width: 84rem;
    height: 84rem;
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 4.21rem;

  @media (orientation: portrait) and (max-width: 767px) {
    margin-bottom: 5rem;
  }
`;

const Icon = styled.img`
  height: 1.25rem;
  width: auto;
  object-fit: contain;
  padding: 0.68rem;

  @media (orientation: portrait) and (max-width: 767px) {
    height: 4.55rem;
    padding: 2.66rem;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 8rem;

  @media (orientation: portrait) and (max-width: 767px) {
    margin-bottom: 14rem;
  }
`;

const TextTitle = styled.h4`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  font-size: 1.56rem;
  line-height: 2.34rem;
  margin: 0;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.72rem;
    line-height: 2.7rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3.8rem;
    line-height: 6rem;
  }
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  font-size: 1.09rem;
  line-height: 1.64rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.22rem;
    line-height: 2.2rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3.33rem;
    line-height: 5rem;
    margin-bottom: 1rem;
  }
`;

const Spacer = styled.div`
  height: 3rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    height: 5rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    height: 15rem;
  }
`;

const OrderedList = styled.ol`
  display: block;
  padding-left: 1em;
`;

type DetailContainerProps = {
  poap: Poap;
};

const DetailContainer = ({ poap }: DetailContainerProps): JSX.Element => {
  const size = useWindowProps();
  const history = useHistory();

  const theme: Theme = useTheme();

  const goBack = () => history.push('/');

  return (
    <Wrapper>
      <HeaderWrapper>
        <TitleWrapper>
          <ButtonWrapper>
            <Button appearance={ButtonAppearance.GHOST} onClick={goBack}>
              <Icon src={Close} alt="Close" />
            </Button>
          </ButtonWrapper>
          <Titles>
            <Auxiliary>
              <AuxiliaryButton type="button" onClick={goBack}>Nature POAPs</AuxiliaryButton>
              {' > '}
              #
              {poap.id}
            </Auxiliary>
            <Title>
              {size.width > 767 ? spaceText(poap.title).map((block, index) => (
              // eslint-disable-next-line react/no-array-index-key
                <span key={index}>
                  {formatText(block)}
                  <br />
                </span>
              )) : formatText(poap.title)}
            </Title>
          </Titles>
        </TitleWrapper>
        <Image src={poap.image} alt={poap.title} />
      </HeaderWrapper>
      <ActionBar poapID={poap.id} />
      <TextWrapper>
        <TextTitle>{poap.detailTitle}</TextTitle>
        <Text>
          {spaceText(poap.detailText).map((block, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index}>
              <br />
              {formatText(block)}
            </span>
          ))}
        </Text>
        <Spacer />
        <TextTitle>How does the POAP claiming process work?</TextTitle>
        <br />
        <OrderedList>
          <li>
            <Text>
              This POAP is available to be claimed by
              {' '}
              {poap.userDescription}
            </Text>
          </li>
          {poap.userCanOpenVaultToGetPoap && (
            <li>
              <Text>
                You can open a vault through
                { ' ' }
                <Link color={theme.colors.button} text="Oasis" href="https://oasis.app/borrow" noUnderline />
                { ' ' }
                or
                { ' ' }
                <Link color={theme.colors.button} text="DefiSaver" href="https://app.defisaver.com/makerdao/create-cdp" noUnderline />
                { ' ' }
              </Text>
            </li>
          )}
          <li>
            <Text>
              If your Ethereum address applies to claim this POAP, please go to the
              {' '}
              <strong>Place your address here</strong>
              {' '}
              box at the top of this page, paste your address on this box, and press the
              {' '}
              <strong>Check</strong>
              {' '}
              button.
              {' '}
              <strong>
                You do not have to connect your Ethereum wallet to this website at any time.
              </strong>
            </Text>
          </li>
          <li>
            <Text>
              The POAP will be immediately minted and sent to your address.
              {' '}
              By default, POAPs are minted to Gnosis Chain.
              {' '}
              You can migrate your POAP to the Ethereum mainnet by following
              {' '}
              <Link color={theme.colors.button} text="these steps" href="https://intercom.help/poap/en/articles/5790336-how-do-i-migrate-my-poap-to-mainnet" noUnderline />
              .
            </Text>
          </li>
          {poap.extraStep && (
            <li>
              <Text>
                After the minting of your POAP, an order will be sent to
                {' '}
                <Link color={theme.colors.button} text="One Tree Planted" href="https://onetreeplanted.org/" noUnderline />
                {' '}
                to plant 10 trees.
              </Text>
            </li>
          )}
          <li>
            <Text>
              You will be able to see your POAP collection on the POAP mobile app (available on
              {' '}
              <Link color={theme.colors.button} text="Android" href="https://play.google.com/store/apps/details?id=xyz.poap.app.mobile" noUnderline />
              {' '}
              &
              {' '}
              <Link color={theme.colors.button} text="iOS" href="https://apps.apple.com/us/app/poap-app/id1567078943" noUnderline />
              ) or on the
              {' '}
              <Link color={theme.colors.button} text="POAP web app" href="https://app.poap.xyz/scan/" noUnderline />
              .
            </Text>
          </li>
          <li>
            <Text>
              For further information, please visit
              {' '}
              <Link color={theme.colors.button} text="POAP's official website" href="https://poap.xyz/" noUnderline />
              .
            </Text>
          </li>
        </OrderedList>
      </TextWrapper>
    </Wrapper>
  );
};

export default DetailContainer;
