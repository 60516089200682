import React, { ChangeEvent, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

import Check from '../assets/icons/check.svg';
import useWindowProps from '../hooks/useWindowProps';
import { check, claim, Results } from '../utils/api';
import usePoapModalStore from '../state/usePoapModalStore';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 41.17rem;

  @media (orientation: portrait) and (max-width: 767px) {
    width: calc(100% - 2rem);
  }
`;

type WrapperProps = {
  error: boolean;
};

const Wrapper = styled.div<WrapperProps>`
    width: 41.17rem;
    height: 4.06rem;
    border-radius: 1.25rem;
    border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.button)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.5rem;
    overflow: hidden;

    @media (orientation: portrait) and (max-width: 767px) {
      width: 100%;
      height: 11rem;
      border-radius: 3.44rem;
    }
`;

const Input = styled.input`
    height: 90%;
    border: none;
    background: none;
    display: flex;
    flex-grow: 1;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 400;
    font-size: 1.09rem;
    line-height: 1.64rem;
    font-family: 'Poppins', 'sans-serif';
    outline: none;

    ::placeholder {
      color: ${({ theme }) => theme.colors.auxiliary};
    }

    @media (orientation: portrait) and (max-width: 1024px) {
      font-size: 1.25rem;
      line-height: 1.9rem;
    }

    @media (orientation: portrait) and (max-width: 767px) {
      font-size: 3rem;
      line-height: 6rem;
    }
`;

type ClaimButtonProps = {
  approved: boolean;
  disabled: boolean;
};

const ClaimButton = styled.button<ClaimButtonProps>`
  outline: none;
  border: none;
  height: 100%;
  width: 10.31rem;
  border-radius: 1.25rem;
  background-color: ${({ theme, approved }) => (approved ? theme.colors.button : 'transparent')};
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
  margin-right: -1px;
  color: ${({ theme, approved, disabled }) => {
    if (disabled) return theme.colors.auxiliary;

    return approved ? theme.colors.basic100 : theme.colors.button;
  }};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  font-family: 'Poppins', 'sans-serif';

  :hover {
    filter: brightness(0.95);
  }

  :active {
    filter: brightness(0.9);
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3.2rem;
    line-height: 6rem;
    border-radius: 3.44rem;
    width: 25.31rem;
  }
`;

const CheckIcon = styled.img`
    width: 0.9rem;
    height: 0.9rem;
    margin: 0 0.9rem;

    @media (orientation: portrait) and (max-width: 1024px) {
      width: 1.03rem;
      height: 1.03rem;
      margin: 0 1.03rem;
    }

    @media (orientation: portrait) and (max-width: 767px) {
      width: 2.47rem;
      height: 2.47rem;
      margin: 0 2.47rem;
    }
`;

const FeedbackMessage = styled.p`
  margin: 1rem 0 0 0;
  color: ${({ theme }) => theme.colors.error};
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-family: 'Poppins', 'sans-serif';

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.03rem;
    line-height: 1.03rem;
    margin: 1.3rem 0 0 0;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 2.7rem;
    line-height: 2.7rem;
    margin: 3rem 0 0 0;
  }
`;

const validEthAddress = /^0x[a-fA-F0-9]{40}$/;

type AddressClaimProps = {
  poapID: string;
};

const AddressClaim = ({ poapID }: AddressClaimProps): JSX.Element => {
  const theme = useTheme();
  const size = useWindowProps();

  const { setPoapId, setAddress: setModalAddress } = usePoapModalStore();

  const [address, setAddress] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [approved, setApproved] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangeAddress = (e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);

  const onPress = async () => {
    // eslint-disable-next-line no-alert
    if (approved) {
      setLoading(true);
      const claimResult = await claim(poapID, address);
      setLoading(false);

      if (claimResult === Results.SUCCESS) {
        setPoapId(poapID);
        setModalAddress(address);
        return;
      }

      setFeedbackMessage('This address has already claimed this POAP.');
      return;
    }

    setLoading(true);
    const checkResult = await check(poapID, address);
    setLoading(false);

    if (checkResult === Results.SUCCESS) {
      setFeedbackMessage('');
      setApproved(true);
      return;
    }

    if (checkResult === Results.ALREADY_CLAIMED) {
      setFeedbackMessage('This address has already claimed this POAP.');
      return;
    }

    setFeedbackMessage("This address isn't eligible.");
  };

  useEffect(() => {
    setApproved(false);
    setFeedbackMessage('');
  }, [address]);

  const getButtonContent = (load: boolean, approve: boolean) => {
    if (load) {
      return (
        <ClipLoader
          color={approve ? theme.colors.basic100 : theme.colors.button}
          loading
          size={size.width > 1024 ? 20 : 14}
        />
      );
    }

    return approve ? 'Claim' : 'Check';
  };

  return (
    <OuterWrapper>
      <Wrapper error={!!feedbackMessage}>
        <Input type="text" value={address} onChange={handleChangeAddress} placeholder="Place your address here" />
        {approved && <CheckIcon src={Check} alt="Check" />}
        <ClaimButton
          approved={approved}
          onClick={onPress}
          disabled={!validEthAddress.test(address) || loading}
        >
          {getButtonContent(loading, approved)}
        </ClaimButton>
      </Wrapper>
      <FeedbackMessage>{feedbackMessage}</FeedbackMessage>
    </OuterWrapper>
  );
};

export default AddressClaim;
