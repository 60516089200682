import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Button, { ButtonAppearance } from '../components/Button';
import useWindowProps from '../hooks/useWindowProps';
import useScrollingValue from '../hooks/useScrollingValue';
import ImgLine1 from '../assets/images/heroLine1.png';
import ImgLine2 from '../assets/images/heroLine2.png';
import ImgLine3 from '../assets/images/heroLine3.png';
import ImgLine4 from '../assets/images/heroLine4.png';

type WrapperProps = {
  height: number;
};

const Wrapper = styled(motion.div)<WrapperProps>`
  z-index: 0;
  width: 100%;
  height: ${(props) => props.height}px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeroLine1 = styled.img`
  z-index: 1;
  position: absolute;
  top: 23.12rem;
  left: 0.07rem;
  width: 44.22rem;
  height: 17.42rem;
  pointer-events: none;

  @media (orientation: portrait) and (max-width: 767px) {
    display: none;
  }
`;

const HeroLine2 = styled.img`
  z-index: 1;
  position: absolute;
  top: 1.27rem;
  right: 5rem;
  width: 3.59rem;
  height: 16.72rem;
  transform: rotate(-61.74deg);
  pointer-events: none;

  @media (orientation: portrait) and (max-width: 767px) {
    display: none;
  }
`;

const HeroLine3 = styled.img`
  z-index: 1;
  position: absolute;
  top: 13.83rem;
  left: 64.14rem;
  width: 23.59rem;
  height: 32.58rem;
  pointer-events: none;

  @media (orientation: portrait) and (max-width: 767px) {
    top: 56.67rem;
    left: 48.33rem;
    width: 48.89rem;
    height: 67.5rem;
  }
`;

const HeroLine4 = styled.img`
  z-index: 1;
  position: absolute;
  top: 28.83rem;
  left: 94.22rem;
  width: 11.33rem;
  height: 7.66rem;
  pointer-events: none;

  @media (orientation: portrait) and (max-width: 767px) {
    display: none;
  }
`;

const Title = styled.h1`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.title};
  font-size: 4.68rem;
  line-height: 5.62rem;
  text-align: center;
  margin: 0;
  margin-bottom: 0.93rem;
  max-width: 80%;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 5.4rem;
    line-height: 6.4rem;
    margin-bottom: 1.8rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 7.77rem;
    line-height: 8.88rem;
    margin: 0 5.55rem 3.33rem;
    max-width: auto;
  }
`;

const Subtitle = styled.h2`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.56rem;
  line-height: 2.34rem;
  text-align: center;
  margin: 0;
  margin-bottom: 3.12rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin-bottom: 4rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3.88rem;
    line-height: 5.83rem;
    margin: 0 5.55rem 8.88rem;
  }
`;

const BrowseText = styled.p`
  color: ${({ theme }) => theme.colors.basic100};
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  margin: 1.1rem 4.84rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.4rem;
    line-height: 2rem;
    margin: 1.3rem 5.4rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3.88rem;
    line-height: 5.83rem;
    margin: 3.75rem 19.16rem;
  }
`;

const Highlight = styled.span`
  background-color: ${({ theme }) => theme.colors.highlight};
`;

type HeroProps = {
  height: number;
};

const Hero = ({ height }: HeroProps): JSX.Element => {
  const size = useWindowProps();

  const opacity = useScrollingValue([0, size.height * 0.75], [1, 0]);
  const y = useScrollingValue([0, size.height * 0.75], [0, 200]);

  const goToPoaps = () => window.scrollTo(0, size.height);

  return (
    <Wrapper
      height={height}
      style={{ opacity, y }}
    >
      <HeroLine1 src={ImgLine1} alt="Decorative background" />
      <HeroLine2 src={ImgLine2} alt="Decorative background" />
      <HeroLine3 src={ImgLine3} alt="Decorative background" />
      <HeroLine4 src={ImgLine4} alt="Decorative background" />
      <Title>
        MakerDAO presents
        {' '}
        <strong>Nature</strong>
        ,
        {size.width > 767 ? <br /> : ' '}
        a collection
        {size.width <= 767 && size.orientation === 'portrait' ? <br /> : ' '}
        of POAPs
      </Title>
      <Subtitle>
        Every living ecosystem begins with
        {' '}
        <Highlight>a single particle of life</Highlight>
        . With dedication and effort,
        <br />
        a small seed can develop into a forest and flourish over time.
      </Subtitle>
      <Button appearance={ButtonAppearance.PRIMARY} onClick={goToPoaps}>
        <BrowseText>Browse POAPs</BrowseText>
      </Button>
    </Wrapper>
  );
};

export default Hero;
