import { motion } from 'framer-motion';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ClipLoader } from 'react-spinners';

import useWindowProps from '../hooks/useWindowProps';
import Close from '../assets/icons/close.svg';
import Button, { ButtonAppearance } from './Button';

type OverlayProps = {
  height: number;
};

const Overlay = styled(motion.div)<OverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.height}px;
  z-index: 300;
  background-color: rgba(0,0,0,0.6);
`;

const ModalWrapper = styled(motion.div)`
  width: 42.38rem;
  padding: 3.8rem 5.31rem 4.92rem;
  height: auto;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.colors.basic100};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 305;

  @media (orientation: portrait) and (max-width: 767px) {
    width: 68.9rem;
    padding: 8rem 8.88rem 15rem;
    height: auto;
    border-radius: 3.44rem;
  }
`;

const ModalActionLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4.37rem;

  @media (orientation: portrait) and (max-width: 767px) {
    margin-bottom: 7.37rem;
  }
`;

const Icon = styled.img`
  height: 1.25rem;
  width: auto;
  object-fit: contain;
  padding: 0.68rem;

  @media (orientation: portrait) and (max-width: 767px) {
    height: 3.5rem;
    padding: 2rem;
  }
`;

const Auxiliary = styled.p`
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.56rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.auxiliary};

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;

type TitleProps = {
  big: boolean;
};

const Title = styled.h4<TitleProps>`
  margin: 0;
  font-size: ${(props) => (props.big ? 1.5 : 1) * 1.56}rem;
  line-height: ${(props) => (props.big ? 1.5 : 1) * 1.875}rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.modalTitle};
  margin-bottom: 1.25rem;

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 4.01rem;
    font-size: ${(props) => (props.big ? 1.5 : 1) * 4.01}rem;
    line-height: ${(props) => (props.big ? 1.5 : 1) * 4.72}rem;
    margin-bottom: 3.2rem;
  }
`;

const Text = styled.p`
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 2.88rem;
    line-height: 4.16rem;
  }
`;

const SpinnerWrapper = styled.div`
  align-self: center;
  margin: 3.92rem 0 0;

  @media (orientation: portrait) and (max-width: 767px) {
    padding: 12rem 0 0;
  }
`;

type ModalProps = {
  auxiliary: string;
  title: string;
  text: JSX.Element;
  visible: boolean;
  handleClose: () => void;
  extra?: JSX.Element | null;
  spinner?: boolean;
  big?: boolean;
};

const transition = { duration: 0.5, ease: [0.6, 0.01, -0.05, 0.9] };

const Modal = ({
  auxiliary, title, text, visible, handleClose, extra = null, spinner, big = false,
}: ModalProps): JSX.Element => {
  const size = useWindowProps();
  const theme = useTheme();

  return (
    <>
      <Overlay
        initial={{ opacity: 0 }}
        animate={{ opacity: visible ? 1 : 0, pointerEvents: visible ? 'all' : 'none' }}
        transition={transition}
        height={size.height}
        onClick={handleClose}
      />
      <ModalWrapper
        style={{ x: '-50%' }}
        initial={{ y: size.height }}
        animate={{ y: visible ? '-50%' : size.height * 0.5 }}
        transition={transition}
      >
        <ModalActionLine>
          <Auxiliary>{auxiliary}</Auxiliary>
          <Button appearance={ButtonAppearance.GHOST} onClick={handleClose}>
            <Icon src={Close} alt="Close" />
          </Button>
        </ModalActionLine>
        <Title big={big}>{title}</Title>
        <Text>{text}</Text>
        {extra}
        {spinner && (
          <SpinnerWrapper>
            <ClipLoader
              color={theme.colors.button}
              loading
              size={size.width > 1024 ? 35 : 25}
            />
          </SpinnerWrapper>
        )}
      </ModalWrapper>
    </>
  );
};

Modal.defaultProps = { extra: null, spinner: false, big: false };

export default Modal;
