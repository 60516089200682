import React from 'react';
import styled from 'styled-components';

export type Theme = {
  colors: { [ key: string ]: string };
};

export enum ButtonAppearance {
  PRIMARY = 'primary',
  OUTLINE = 'outline',
  GHOST = 'ghost',
  ACCENT = 'accent',
}

const getColors = (
  theme: Theme, appearance: ButtonAppearance,
): { border: string; background: string; } => {
  const colors = {
    [ButtonAppearance.PRIMARY]: {
      background: theme.colors.button,
      border: theme.colors.button,
    },
    [ButtonAppearance.OUTLINE]: {
      background: 'transparent',
      border: theme.colors.button,
    },
    [ButtonAppearance.GHOST]: {
      background: theme.colors.buttonLight,
      border: theme.colors.buttonLight,
    },
    [ButtonAppearance.ACCENT]: {
      background: theme.colors.accentLight,
      border: theme.colors.accentLight,
    },
  };

  return colors[appearance];
};

type ButtonProps = {
  appearance: ButtonAppearance;
  onClick: () => void;
  children: JSX.Element;
};

type WrapperProps = {
  appearance: ButtonAppearance;
};

const Wrapper = styled.button<WrapperProps>`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, appearance }) => getColors(theme, appearance).background};
  border: 1px solid ${({ theme, appearance }) => getColors(theme, appearance).border};
  border-radius: 1.25rem;
  font-family: inherit;
  cursor: pointer;
  transition: filter 0.3s ease-out;

  :hover {
    filter: brightness(0.95);
  }

  :active {
    filter: brightness(0.9);
  }

  @media (orientation: portrait) and (max-width: 767px) {
    border-radius: 4.44rem;
  }
`;

const Button = ({ appearance, onClick, children }: ButtonProps): JSX.Element => (
  <Wrapper appearance={appearance} onClick={onClick}>
    {children}
  </Wrapper>
);

export default Button;
