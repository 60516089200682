import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: auto;
  pointer-events: all;
  width: 87.04rem;
  padding: 0 6.48rem;
  margin-top: 1rem;
  pointer-events: all;
  display: inline-block;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (orientation: portrait) and (max-width: 1024px) {
    margin-top: 10.5rem;
  }
`;

type GridProps = {
  children: JSX.Element[];
};

const Grid = ({ children }: GridProps): JSX.Element => (
  <Wrapper>
    {children}
  </Wrapper>
);

export default Grid;
