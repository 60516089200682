import {
  MotionValue, useSpring, useTransform, useViewportScroll,
} from 'framer-motion';

const useScrollingValue = (fromRange: number[], toRange: number[]): MotionValue<number> => {
  const { scrollY } = useViewportScroll();

  const transformedValue = useTransform(scrollY, fromRange, toRange);
  const physics = { damping: 15, mass: 0.27, stiffness: 55 };
  const smoothedValue = useSpring(transformedValue, physics);

  return smoothedValue;
};

export default useScrollingValue;
