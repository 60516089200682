import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { AnimatePresence } from 'framer-motion';

import Home from './screens/Home';
import './App.css';
import { Theme } from './components/Button';
import Detail from './screens/Detail';
import Modal from './components/Modal';
import useWindowProps from './hooks/useWindowProps';
import useModalStore from './state/useModalStore';
import PoapModal from './components/PoapModal';
import HeadedRoute from './components/HeadedRoute';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: { [ key: string ]: string };
  }
}

type GlobalStyleProps = {
  height: number;
};

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html {
    font-size: min(1vw, 15px);
    height: ${(props) => props.height}px;
  }

  body {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    background-color: #fff;
    padding: 0;
    margin: 0;
    height: ${(props) => props.height}px;
  }
`;

const theme: Theme = {
  colors: {
    primary: '#3F4565',
    button: '#4FC4CF',
    buttonLight: 'rgba(79, 196, 207, 0.1)',
    accentLight: 'rgba(213, 220, 243, 0.5)',
    title: '#353B57',
    poapBackground: '#F7FAFF',
    basic100: '#fff',
    highlight: '#F7F6FF',
    lightText: '#7582B4',
    auxiliary: '#C4CAE8',
    modalTitle: '#353B57',
    topbarBorder: '#F6F9FF',
    error: '#F53030',
  },
};

const App = (): JSX.Element => {
  const size = useWindowProps();

  const { open: openModal, setOpen } = useModalStore();

  const closeArtistModal = () => setOpen(false);

  return (
    <Router>
      <GlobalStyle height={size.height} />
      <ThemeProvider theme={theme}>
        <Route
          render={({ location }) => (
            <AnimatePresence exitBeforeEnter>
              <Modal
                key="artist-modal"
                auxiliary="Artist"
                title="Ryan Koopmans"
                text={(
                  <>
                    Ryan Koopmans (BA, MFA) is a Canadian-Dutch artist interested in the built
                    environment and the societies that are shaped by those environments.
                    <br />
                    His book &apos;Vantage&apos; published in 2020 depicts surreal structures
                    in our world&apos;s megacities and urban landscapes.
                    <br />
                    Koopmans&apos; artwork is collected & exhibited worldwide and been featured
                    by WIRED, Architectural Digest, CNN, Vogue, GQ, The Guardian,
                    Mercedes-Benz & more.
                  </>
                )}
                visible={openModal}
                handleClose={closeArtistModal}
              />
              <PoapModal key="poap-modal" />
              <Switch location={location} key={location.pathname}>
                <HeadedRoute path="/detail/:id">
                  <Detail />
                </HeadedRoute>
                <HeadedRoute path="/">
                  <Home />
                </HeadedRoute>
              </Switch>
            </AnimatePresence>
          )}
        />
      </ThemeProvider>
    </Router>
  );
};

export default App;
