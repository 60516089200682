import React, { useEffect } from 'react';
import styled from 'styled-components';
import { animated, useSpring as useReactSpring } from '@react-spring/web';

import useWindowProps from '../hooks/useWindowProps';
import useScrollingValue from '../hooks/useScrollingValue';
import poaps from '../data/poaps';
import GridPoapCard from '../components/GridPoapCard';
import Grid from '../components/Grid';

type WrapperProps = {
  topMargin: number;
};

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.poapBackground};
  z-index: 100;
  padding: 1.6rem 0 6.6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  @media (orientation: portrait) and (max-width: 1024px) {
    padding: 2rem 0 8rem;
  }
`;

type SvgProps = {
  height: number;
};

const Svg = styled.svg<SvgProps>`
  position: absolute;
  top: ${(props) => -props.height * 0.7}px;
  left: 0;
`;

const PoapContainer = (): JSX.Element => {
  const size = useWindowProps();

  const curvature = useScrollingValue([0, size.height * 0.5], [0, -1.5]);

  const [{ y }, setY] = useReactSpring(() => ({ y: 0 }));

  useEffect(() => {
    curvature.onChange((latest) => setY({ y: latest }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper topMargin={size.height}>
      <Svg xmlns="http://www.w3.org/2000/svg" width={size.width} height={size.width * 0.35} viewBox="0 -1 7 1">
        <animated.path
          d={y.to((value) => `M 7 -1 V 0 A 0 1.42 0 0 1 0 0 T 0 -1 C 2 ${value} 5 ${value} 7 -1`)}
          fill="#F7FAFF"
        />
      </Svg>
      <Grid>
        {poaps.map((poap) => (
          <GridPoapCard
            key={poap.title}
            id={poap.id}
            image={poap.image}
            title={poap.title}
            text={poap.text}
          />
        ))}
      </Grid>
    </Wrapper>
  );
};

export default PoapContainer;
