import Vault from '../assets/images/poaps/vault.gif';
import Year from '../assets/images/poaps/year.gif';
import DefiSaver from '../assets/images/poaps/defiSaver.gif';
import New from '../assets/images/poaps/new.gif';

import VaultWebp from '../assets/images/poaps/vault.webp';
import YearWebp from '../assets/images/poaps/year.webp';
import DefiSaverWebp from '../assets/images/poaps/defiSaver.webp';
import NewWebp from '../assets/images/poaps/new.webp';

export type Poap = {
  id: string;
  image: string;
  cleanTitle: string;
  title: string;
  text: string;
  detailTitle: string;
  detailText: string;
  userDescription: string;
  extraStep: boolean;
  userCanOpenVaultToGetPoap: boolean;
};

const poaps: Poap[] = [
  {
    id: '1787',
    image: VaultWebp,
    cleanTitle: 'American Arborvitae, the tree of life',
    title: '**American Arborvitae**, \nthe tree of life',
    text:
      'Pressing the button that generates new Dai through a Maker Vault is an act of life. This is the representation of that miraculous moment: The American Arborvitae, known as the tree of life.',
    detailTitle: 'What happens when you open a new Maker Vault?',
    detailText:
      'You put your collateral into the Maker Protocol, Maker takes it and brings you the possibility to issue Dai, you select your amount, press a button and then a new bulk of Dai is created. This final step is one of the most important events of the future of finance.\n\nYou are pressing a button and creating a new amount of electronic currency that is automatically stable and free to use anywhere by anyone in the entire world.\n\nOn top of that, this new currency is not controlled or monitored by any central entity, it\'s controlled by you and no one else.\n\nThese features are powerful to bring financial inclusion, stability, freedom and connectivity to anyone who needs it. This is like the miracle of life in the future of finance. A new super-currency was born with one button.\n\nIt\'s something we have to celebrate. This is why we delivered this American Arborvitae, called "the tree of life".\n\nScurvy, a disease of deficiency of vitamin C, shocked French explorers on their second trip to Canada in 1535-1536, killing a large number of them. The captain Jacques Cartier asked for help from the American Natives, and they offered him an herbal tea. \n\nThe tea was miraculous, recovering all these men even in the worst cases. Jacque called the plant used for tea as "arborvitae", Latin for "tree of life."\n\nEven in a tense situation, human warmth provided a cure for a disease among people from "different worlds."\n\nDai, itself, is part of the recovering process that the different finance worlds need, decentralized and centralized both. Putting the best of both in a solution for those who need it most, and then healing with financial inclusion.\n\n**Let\'s celebrate the life of Dai together and the new born for everyone\'s finances.**',
    userDescription:
      'users who have opened a Maker Vault and generated Dai from it before May 31st, 2022.',
    extraStep: false,
    userCanOpenVaultToGetPoap: true,
  },
  {
    id: '1786',
    image: YearWebp,
    cleanTitle: 'The oldest living organism on Earth',
    title: '**The oldest** \nliving organism on Earth',
    text:
      'This colony of quaking aspen trees is considered the oldest living organism in the world. It represents your Maker Vault with more than one year of life, and all the Dai it brought to life since the Vault has opened.',
    detailTitle: 'How long do you think a tree lives?',
    detailText:
      'This is Pando, a colony of quaking aspen trees, known as the oldest clonal trees that are up to 14,000 years old.\n\n**Actually, it\'s quite difficult to determine Pando\'s exact age. Here is why:**\n\nA clonal colony is a group of trees that are genetically identical, have grown all originating vegetatively, with no seeds planted, from a single ancestor. Quaking aspens are able to reproduce themselves clonally, sending seedlings through their roots.\n\nEach quaking aspen tree appears to be unique, but underground they are interconnected and are all clones of the same plant. They\'re all the same organism and they always were.\n\nSo, Pando is a group of trees that emerged directly from the same tree that may have a longevity of more than 14,000 thousand years, even 80,000 according to other estimates. **However, the point is that Pando is possibly the oldest living organism on Earth.**\n\nWhen talking about Pando, its longevity is largely determined by the environment in which it was grown. The conditions for living all that time must have been perfect.\n\nThis is the representation of your Maker Vault with more than 1 year of life. Because it\'s not just about depositing collateral and issuing Dai, it\'s also about the care your Vault receives and the ecosystem in which it lives.\n\nJust like that single quaking aspen 14,000 years ago was the starting core of life that originated Pando, your Dai issued more than 1 year ago has spread as a particle of life that delivered stability, profits, capital flows and economic life to more than one living being in the DeFi ecosystem.\n\nAt the same time, your Vault conditions have allowed this to happen. All this time it was alive thanks to you, and for you and the care that you delivered to your Vault, the Dai issued will continue to give life to the environment where it runs.',
    userDescription:
      'users who own an active Maker Vault that is over 1 year old and have generated Dai from it before May 31st, 2022.',
    extraStep: false,
    userCanOpenVaultToGetPoap: true,
  },
  {
    id: '1755',
    image: DefiSaverWebp,
    cleanTitle: 'The chestnut tree and its resources to life',
    title: '**The chestnut tree** and its resources to life',
    text:
      'The chestnut tree represents the care DeFi Saver took to your Maker Vault. Just like this tree provides resources for life, DeFi Saver has provided your Vault with all the necessary elements to keep it healthy.',
    detailTitle:
      'This is a chestnut tree. You could walk past it and not place much importance, it\'s just like another tree, right?',
    detailText:
      'Not at all. This is one of the most important plants that generate essential resources for life: The chestnut tree produces up to 100 pounds of chestnut per year, provides ample shade, grows over a broad climatic range and can live up to 800 years.\n\nA simple chestnut tree is a protector of life, even for cold winters and for several generations. In fact, it could be considered a shield against unnoticed events. \n\nYou may have heard squirrels tales about taking chestnuts for the winter. It\'s a representation of reality. Actually, one chestnut tree can provide food for five squirrels over an entire year.\n\nThink about it: In a hot or cold place, the chetsnut tree will provide food, shade, home and life to many other living creatures for hundreds of years.\n\nJust like the squirrel that saves chestnuts for the winter and avoids its own death, the \'liquidation\' is an event that can always be avoided when managing a Maker Vault.\n\nThis is why the chestnut tree can be designated as the safeguard of your Maker Vault. But now, from this moment, it\'s the representation of why you\'re using DeFi Saver: A defense that provides you with resources to keep your Vault healthy, no matter what unexpected situation arises.\n\nThis is the recognition for putting that effort to defend your Maker Vault through DeFi Saver. **This is why it is the best tool for protecting matters when you are managing the future of finance.**',
    userDescription:
      'users who have opened a Maker Vault and generated Dai from it through DeFi Saver before May 31st, 2022.',
    extraStep: false,
    userCanOpenVaultToGetPoap: false,
  },
  {
    id: '1831',
    image: NewWebp,
    cleanTitle: 'Planting a new life is contributing to an ecosystem',
    title: 'Planting a **new life** is contributing to an ecosystem',
    text:
      'A planted seed that grows and becomes a new living being: Since that moment, a new life has been created, like the Dai that you can create through a Maker Vault and that can be loaded with life, ready to be absorbed by the DeFi world.',
    detailTitle: 'How would you feel if you planted a tree?',
    detailText:
      'At the time of planting you are giving life. You are giving life to a new being.\n\nYou did it in the 10 seconds it took you to bury a seed and wet the ground on top of it. But that\'s not the only impact. \n\nIn fact, you are planting a living being that will over time contribute to an ecosystem, reduce pollution, provide a home for other living creatures, generate food and generate more life around it. You are planting something that will grow into something much bigger.\n\nWhat would you feel now with a couple more seeds planted? Maybe it was the same time it took you to open a Maker Vault.\n\n**A deposited collateral: The seed that you buried.**\n\nThe Dai received: The growth of this seed, until the moment it becomes a plant that creates more life and resources for other lifes.\n\nDai is the seed turned into life that offers benefits in the ecosystem where it lives: a better, stable, and decentralized currency for the people that need it and a generator of profits, capital flow and financial freedom in the DeFi world.\n\nThis medal representing the birth of a new living being not only works as an analogy for a Maker Vault and the Dai that was born from it, but also demonstrates how easy it is to plant a tree compared to how easy it was to open the Vault.\n\nThinking about living and sustainable ecosystems while interacting with the latest money technology may sound distant.\n**But in reality, the future is already here: let\'s make electronic, decentralized and clean money.**',
    userDescription:
      'users who open a Maker Vault and generate Dai from it after June 5th, 2022.',
    extraStep: true,
    userCanOpenVaultToGetPoap: true,
  },
];

export default poaps;
