import create from 'zustand';

type PoapModalState = {
  poapId: string | null;
  setPoapId: (poapId: string | null) => void;
  address: string | null;
  setAddress: (address: string | null) => void;
};

const useStore = create<PoapModalState>((set) => ({
  poapId: null,
  setPoapId: (poapId: string | null) => set(() => ({ poapId })),
  address: null,
  setAddress: (address: string | null) => set(() => ({ address })),
}));

export default useStore;
