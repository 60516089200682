type Helmet = {
  title: string;
  description: string;
};

const headers: { [index: string]: Helmet } = {
  '/': {
    title: 'Nature - A collection of POAPs by MakerDAO',
    description: 'Every living ecosystem begins with a single particle of life. With dedication and effort, a small seed can develop into a forest and flourish over time.',
  },
  '/detail/1787': {
    title: 'Nature - American Arborvitae, the tree of life',
    description: 'You are creating a new electronic currency, stable and free to use anywhere by anyone in the world. We celebrate this milestone with the tree of life.',
  },
  '/detail/1786': {
    title: 'Nature - The oldest living organism on Earth',
    description: 'Your Dai issued more than 1 year ago has spread as a particle of life that delivered stability to more than one living being in the DeFi ecosystem.',
  },
  '/detail/1755': {
    title: 'Nature - The chestnut tree and its resources to life',
    description: "The chestnut tree is designated as the safeguard of your Maker Vault. It's the representation of why you're using DeFi Saver.",
  },
  '/detail/1831': {
    title: 'Nature - Planting a new life is contributing to an ecosystem',
    description: 'A deposited collateral: The seed that you buried. The Dai received: The growth of this seed, until the moment it becomes a plant that creates more life.',
  },
  default: {
    title: 'Nature - A collection of POAPs by MakerDAO',
    description: 'Every living ecosystem begins with a single particle of life. With dedication and effort, a small seed can develop into a forest and flourish over time.',
  },
};

export default headers;
