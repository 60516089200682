import React, { useEffect, useState } from 'react';
import { Globe } from 'react-feather';
import styled from 'styled-components';

import poaps from '../data/poaps';
import usePoapModalStore from '../state/usePoapModalStore';
import { check, plant, Results } from '../utils/api';
import { formatText, sleep } from '../utils/utils';
import Modal from './Modal';

const ExtraWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem 0;

  @media (orientation: portrait) and (max-width: 767px) {
    margin: 5rem 0 0 0;
  }
`;

const GlobeIcon = styled(Globe)`
  color: ${({ theme }) => theme.colors.button};
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.25rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    width: 3.5rem;
    height: 3.5rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    display: none;
  }
`;

const Text = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.button};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 2.88rem;
    line-height: 4.16rem;
  }
`;

const PoapModal = (): JSX.Element => {
  const {
    poapId, setPoapId, address, setAddress,
  } = usePoapModalStore();

  const [title, setTitle] = useState('Almost there...');
  const [text, setText] = useState('The POAP token is on its way to your wallet. This may take a couple of seconds.');
  const [showSpinner, setShowSpinner] = useState(true);

  const [treePlanted, setTreePlanted] = useState(false);

  const reset = () => {
    setTitle('Almost there...');
    setText('The POAP token is on its way to your wallet. This may take a couple of seconds.');
    setShowSpinner(true);
  };

  const closePoapModal = () => {
    setAddress(null);
    setPoapId(null);
    sleep(1000).then(() => reset());
  };

  const renderExtra = () => {
    if (poapId !== '1831' || !treePlanted) return null;

    return (
      <ExtraWrapper>
        <GlobeIcon />
        <Text>
          Thanks to OneTreePlanted, we&apos;ve planted a tree on
          your behalf in an effort to make the world a better place.
        </Text>
      </ExtraWrapper>
    );
  };

  useEffect(() => {
    if (poapId && address) {
      const recurringCheck = setInterval(async () => {
        const checkResult = await check(poapId, address);

        if (checkResult === Results.SUCCESS || checkResult === Results.ERROR) return;

        setTitle('Congratulations!');
        setText(`**${poaps.find((poap) => poap.id === poapId)?.cleanTitle}** badge was added to your collection.`);
        setShowSpinner(false);
        clearInterval(recurringCheck);
      }, 1000);
    }
  }, [poapId, address]);

  useEffect(() => {
    const plantTree = async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const plantResult = await plant(address!);

      if (plantResult === Results.SUCCESS) setTreePlanted(true);
    };

    if (poapId === '1831' && address) plantTree();
  }, [poapId, address]);

  return (
    <Modal
      auxiliary=""
      title={title}
      text={(
        <>
          {formatText(text)}
        </>
      )}
      extra={renderExtra()}
      visible={!!poapId && !!address}
      handleClose={closePoapModal}
      spinner={showSpinner}
      big
    />
  );
};

export default PoapModal;
