import { useState, useEffect } from 'react';

type WindowProps = {
  width: number;
  height: number;
  orientation: string;
  isMobile: boolean;
};

const useWindowProps = (): WindowProps => {
  const getSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
    orientation:
      window.innerWidth > window.innerHeight ? 'landscape' : 'portrait',
    isMobile: window.innerWidth < window.innerHeight && window.innerWidth < 768,
  });

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowProps;
