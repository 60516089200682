import React from 'react';
import styled from 'styled-components';

type LinkTextProps = {
  color?: string;
  noUnderline?: boolean;
};

const LinkText = styled.a<LinkTextProps>`
  font-weight: 600;
  color: ${({ color, theme }) => color || theme.colors.button};
  text-decoration: ${(props) => (props.noUnderline ? 'none' : 'underline')};
  text-decoration-color: ${({ color, theme }) => color || theme.colors.button};
  cursor: pointer;
  transition: filter 0.3s ease-out;

  :hover {
    filter: brightness(0.95);
  }

  :active {
    filter: brightness(0.9);
  }
`;

type LinkProps = {
  text: string;
  href: string;
  color?: string;
  noUnderline?: boolean;
  onClick?: () => void;
};

const Link = ({
  text, href, color, noUnderline, onClick,
}: LinkProps): JSX.Element => (
  <LinkText href={href} target="_blank" rel="noopener noreferrer" color={color} noUnderline={noUnderline} onClick={onClick}>
    {text}
  </LinkText>
);

Link.defaultProps = {
  color: undefined,
  noUnderline: false,
  onClick: () => {},
};

export default Link;
