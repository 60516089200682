import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Theme } from '../components/Button';
import Link from '../components/Link';

const Wrapper = styled.div`
  width: calc(100% - 7.8rem);
  height: 15rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.basic100};
  padding: 5.31rem 3.9rem 0;
  pointer-events: all;
  border-top: 1px solid ${({ theme }) => theme.colors.poapBackground};

  @media (orientation: portrait) and (max-width: 1024px) {
    height: 20rem;
    padding: 7.31rem 4.5rem 0;
    width: calc(100% - 9rem);
  }

  @media (orientation: portrait) and (max-width: 767px) {
    height: 20.33rem;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 12rem 0;
  }
`;

const ClosingText = styled.p`
  font-weight: 400;
  font-size: 0.93rem;
  line-height: 1.56rem;
  color: ${({ theme }) => theme.colors.lightText};
  margin: 0;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.3rem;
    line-height: 1.9rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3rem;
    line-height: 5rem;
  }
`;

const Brands = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Spacer = styled.div`
  width: 3.5rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    width: 4.2rem;
  }

  @media (orientation: portrait) and (max-width: 1024px) {
    width: 10rem;
  }
`;

const Footer = (): JSX.Element => {
  const theme: Theme = useTheme();

  return (
    <Wrapper>
      <ClosingText>
        Made with love by
        {' '}
        <Link color={theme.colors.button} text="Maker Growth" href="mailto:hello@makergrowth.com" />
        {' '}
        and POAP
      </ClosingText>
      <Brands>
        <ClosingText>
          <Link color={theme.colors.lightText} text="MakerDAO" href="https://makerdao.com/" noUnderline />
        </ClosingText>
        <Spacer />
        <ClosingText>
          <Link color={theme.colors.lightText} text="POAP" href="https://poap.xyz/" noUnderline />
        </ClosingText>
      </Brands>
    </Wrapper>
  );
};

export default Footer;
