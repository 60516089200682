import React from 'react';
import { Route } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import headers from '../data/headers';

type HeadedRouteProps = {
  children: JSX.Element | JSX.Element[];
  location?: {
    pathname: string;
  };
  path: string;
};

const HeadedRoute = ({ children, ...rest }: HeadedRouteProps): JSX.Element => (
  <Route
    path={rest.path}
    render={() => (
      <>
        <Helmet>
          <title>{headers[rest.location?.pathname || '/']?.title ?? headers.default.title}</title>
          <meta
            name="description"
            content={headers[rest.location?.pathname || '/']?.description ?? headers.default.description}
          />
          <meta name="robots" content="index,follow" />
          <meta name="resource-type" content="document" />
          <meta name="distribution" content="global" />
          <meta name="revisit-after" content="1 days" />
          <meta name="rating" content="general" />
        </Helmet>
        {children}
      </>
    )}
  />
);

HeadedRoute.defaultProps = {
  location: {
    pathname: '/',
  },
};

export default HeadedRoute;
