import React from 'react';
import styled from 'styled-components';

import Maker from '../assets/images/maker.svg';
import Poap from '../assets/images/poap.svg';

const Wrapper = styled.div`
  flex: 1;
  width: auto;
  height: 6.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 3.9rem;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: all;

  @media (orientation: portrait) and (max-width: 1024px) {
    height: 8.5rem;
    padding-left: 4.5rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    height: 8.5rem;
    width: 100%;
    padding: 0;
    padding-bottom: 7rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 7rem;
    border-bottom-color: ${({ theme }) => theme.colors.topbarBorder};
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
`;

const LogosGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const LogoLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 4rem;
  text-decoration: none;
  cursor: pointer;

  @media (orientation: portrait) and (max-width: 1024px) {
    margin-right: 4.5rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    margin-right: 0;
  }
`;

const MakerLogo = styled.img`
  width: 3.1rem;
  height: auto;
  object-fit: contain;
  margin-right: 1.87rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    width: 4rem;
    margin-right: 2.2rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    width: 6.66rem;
    margin-right: 13.1rem;
  }
`;

const PoapLogo = styled.img`
  width: 2.34rem;
  height: auto;
  object-fit: contain;
  margin-right: 1.87rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    width: 2.7rem;
    margin-right: 2.2rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    width: 5.55rem;
    margin: 0;
  }
`;

const BrandText = styled.h3`
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.56rem;
  color: ${({ theme }) => theme.colors.primary};

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    display: none;
  }
`;

const Topbar = (): JSX.Element => (
  <Wrapper>
    <LogosGroup>
      <LogoLink href="https://makerdao.com/" target="_blank" rel="noopener noreferrer">
        <MakerLogo src={Maker} alt="MakerDAO logo" />
        <BrandText>MakerDAO</BrandText>
      </LogoLink>
      <LogoLink href="https://poap.xyz/" target="_blank" rel="noopener noreferrer">
        <PoapLogo src={Poap} alt="POAP logo" />
        <BrandText>POAP</BrandText>
      </LogoLink>
    </LogosGroup>
  </Wrapper>
);

export default Topbar;
