import React from 'react';
import styled from 'styled-components';
import useWindowProps from '../hooks/useWindowProps';
import useModalStore from '../state/useModalStore';

const TextWrapper = styled.h5`
  font-size: 0.93rem;
  line-height: 1.40rem;
  text-align: right;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 3.33rem;
    line-height: 5rem;
    margin-bottom: 10rem;
    text-align: right;
  }
`;

type ArtistButtonProps = {
  hasMargin: boolean;
};

const ArtistButton = styled.button<ArtistButtonProps>`
  border: none;
  background: none;
  outline: none;
  color: ${({ theme }) => theme.colors.button};
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  font-weight: 500;
  padding: 0;
  margin: 0;
  margin-left: ${(props) => (props.hasMargin ? '1rem' : '0rem')};
  font-family: 'Poppins', 'sans-serif';
  cursor: pointer;

  :hover {
    filter: brightness(0.95);
  }

  :active {
    filter: brightness(0.9);
  }
`;

const ArtistModal = (): JSX.Element => {
  const size = useWindowProps();

  const { setOpen } = useModalStore();

  const handleOpenModal = () => setOpen(true);

  return (
    <>
      <TextWrapper>
        Artist:
        {size.width > 767 && <br />}
        <ArtistButton hasMargin={size.width <= 767} onClick={handleOpenModal}>
          Ryan Koopmans
        </ArtistButton>
      </TextWrapper>
    </>
  );
};

export default ArtistModal;
