import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { formatText } from '../utils/utils';

const Wrapper = styled.button`
  border: none;
  text-align: left;
  font-family: 'Poppins', 'sans-serif';
  width: 41.87rem;
  height: 39.34rem;
  border-radius: 1.56rem;
  background-color: ${({ theme }) => theme.colors.basic100};
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.5s ease-out;
  padding: 0;
  margin-bottom: 3.44rem;

  &:nth-child(odd) {
    margin-right: 1.62rem;
  }

  &:nth-child(even) {
    margin-left: 1.62rem;
  }

  :hover {
    transform: scale(1.02);
  }

  @media (orientation: portrait) and (max-width: 1024px) {
    height: 45.99rem;
    border-radius: 1.87rem;
    margin-bottom: 7.44rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    width: 100%;
    height: 100.26rem;
    border-radius: 3.31rem;
    margin-bottom: 10.44rem;

    &:nth-child(odd) {
      margin-right: 0;
    }

    &:nth-child(even) {
      margin-left: 0;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Image = styled.img`
  width: 100%;
  height: 21.4rem;
  object-fit: cover;

  @media (orientation: portrait) and (max-width: 767px) {
    height: 46.06rem;
  }
`;

const Info = styled.div`
  width: calc(100% - 3.74rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2.18rem 1.87rem 3.28rem;

  @media (orientation: portrait) and (max-width: 1024px) {
    width: 37.98rem;
    padding: 3.16rem 2.24rem 3.93rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    width: calc(100% - 10rem);
    padding: 5.3rem 5rem 6.60rem;
  }
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 1.56rem;
  line-height: 1.79rem;
  color: ${({ theme }) => theme.colors.title};
  margin: 0;
  margin-bottom: 1rem;
  white-space: normal;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.87rem;
    line-height: 2.15rem;
    margin-bottom: 1.2rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 4.216rem;
    line-height: 5rem;
    margin-bottom: 3.3rem;
  }
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 1.09rem;
  line-height: 1.64rem;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
  white-space: normal;
  flex-grow: 1;
  display: flex;

  @media (orientation: portrait) and (max-width: 1024px) {
    font-size: 1.31rem;
    line-height: 2.27rem;
  }

  @media (orientation: portrait) and (max-width: 767px) {
    font-size: 2.8rem;
    line-height: 4.8rem;
  }
`;

type GridPoapCardProps = {
  id: string;
  image: string;
  title: string;
  text: string;
};

const GridPoapCard = ({
  id, image, title, text,
}: GridPoapCardProps): JSX.Element => {
  const history = useHistory();

  const goToPoapDetail = () => {
    history.push(`/detail/${id}`);
  };

  return (
    <Wrapper onClick={goToPoapDetail}>
      <Content>
        <Image src={image} alt={title} />
        <Info>
          <Title>{formatText(title, true)}</Title>
          <Text>{text}</Text>
        </Info>
      </Content>
    </Wrapper>
  );
};

export default GridPoapCard;
