import { motion, useViewportScroll } from 'framer-motion';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Topbar from '../components/Topbar';
import DetailContainer from '../containers/DetailContainer';
import Footer from '../containers/Footer';
import SmoothScroll from '../containers/SmoothScroll';
import fadeTransition from '../data/fadeTransition';
import poaps from '../data/poaps';

type DetailProps = {
  id: string;
};

const Detail = (): JSX.Element => {
  const history = useHistory();
  const { scrollY } = useViewportScroll();
  const { id } = useParams<DetailProps>();

  const poap = poaps.find((p) => p.id === id);

  useEffect(() => {
    if (!poap) history.push('/');
  }, [poap, history]);

  useEffect(() => {
    scrollY.set(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!poap) return <></>;

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit={{ opacity: 0 }}
      transition={fadeTransition}
    >
      <SmoothScroll>
        <Topbar />
        <DetailContainer poap={poap} />
        <Footer />
      </SmoothScroll>
    </motion.div>
  );
};

export default Detail;
