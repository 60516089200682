import React, {
  useRef, useState, useCallback, useLayoutEffect,
} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import useScrollingValue from '../hooks/useScrollingValue';

const SmoothScrollWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  will-change: transform;
  z-index: 5;
  pointer-events: none;
`;

type SmoothScrollProps = {
  children: JSX.Element[];
};

const SmoothScroll = ({ children }: SmoothScrollProps): JSX.Element => {
  // scroll container
  const scrollRef = useRef(null);

  // page scrollable height based on content length
  const [pageHeight, setPageHeight] = useState(0);

  // update scrollable height when browser is resizing
  const resizePageHeight = useCallback((entries) => {
    entries.forEach((entry: ResizeObserverEntry) => setPageHeight(entry.contentRect.height));
  }, []);

  // observe when browser is resizing
  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => resizePageHeight(entries));

    if (scrollRef && scrollRef.current) resizeObserver.observe(scrollRef.current);

    return () => resizeObserver.disconnect();
  }, [scrollRef, resizePageHeight]);

  const y = useScrollingValue([0, pageHeight], [0, -pageHeight]);

  return (
    <>
      <SmoothScrollWrapper
        ref={scrollRef}
        style={{ y }} // translateY of scroll container using negative scroll value
      >
        {children}
      </SmoothScrollWrapper>
      {/* blank div that has a dynamic height based on the content's inherent height */}
      {/* this is neccessary to allow the scroll container to scroll... */}
      {/* ... using the browser's native scroll bar */}
      <div style={{ height: pageHeight }} />
    </>
  );
};

export default SmoothScroll;
