import axios from 'axios';

const BASE_API_URL = 'https://api.poap.xyz';

export enum Results {
  SUCCESS = 'success',
  ALREADY_CLAIMED = 'already_claimed',
  ERROR = 'error',
}

export const check = (deliveryID: string, address: string): Promise<string> => (
  axios.get(`${BASE_API_URL}/delivery-addresses/${deliveryID}/address/${address}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.data.claimed === false) {
        return Results.SUCCESS;
      }

      return Results.ALREADY_CLAIMED;
    })
    .catch(() => Results.ERROR)
);

export const claim = (deliveryID: string, address: string): Promise<string> => (
  axios.post(`${BASE_API_URL}/actions/claim-delivery-v2`, {
    address,
    id: parseInt(deliveryID, 10),
  })
    .then(() => Results.SUCCESS)
    .catch(() => Results.ERROR)
);

export const plant = (address: string): Promise<string> => (
  axios.post('https://us-central1-maker-poap-test.cloudfunctions.net/plantTree', { address })
    .then(() => Results.SUCCESS)
    .catch(() => Results.ERROR)
);
